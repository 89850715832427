<template>
  <div
    v-if="options.length && (selected || sort)"
    class="drop-down"
    :class="{ 'drop-down--active': isActive }"
  >
    <div v-if="caption" class="drop-down__caption">
      {{ caption }}
    </div>

    <div class="drop-down__wrapper" @mouseenter="open()" @mouseleave="close()">
      <div class="drop-down__selected" @click="open()">
        {{ locale ? $t(locale + "." + value) : value }}
        <span v-if="units" class="drop-down__units">{{ units }}</span>
        <svg-icon width="7" height="4" name="arrow" class="drop-down__arrow" />
      </div>

      <div class="drop-down__list">
        <div
          v-for="option in options"
          :key="option"
          v-show="sort || option != value"
          class="drop-down__item"
          @click="set(option)"
        >
          {{ locale ? $t(locale + "." + option) : option }}
          <span v-if="units" class="drop-down__units">{{ units }}</span>

          <div
            v-if="sort"
            class="drop-down__sorting-arrows"
            :class="{
              'drop-down__sorting-arrows--desc':
                sort[0] == option && sort[1] === 'desc',
              'drop-down__sorting-arrows--asc':
                sort[0] == option && sort[1] === 'asc'
            }"
          >
            <svg-icon
              width="7"
              height="4"
              name="arrow"
              class="drop-down__sorting-arrow drop-down__sorting-arrow--asc"
            />
            <svg-icon
              width="7"
              height="4"
              name="arrow"
              class="drop-down__sorting-arrow drop-down__sorting-arrow--desc"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "selected",
    "options",
    "callback",
    "locale",
    "caption",
    "units",
    "sort"
  ],
  data: function () {
    return {
      isActive: false
    };
  },
  computed: {
    value() {
      return this.sort ? this.sort[0] : this.selected;
    }
  },
  methods: {
    set: function (value) {
      if (this.callback) this.callback(value);
      this.close();
    },
    toggle: function () {
      this.isActive = !this.isActive;
    },
    open: function () {
      this.isActive = true;
    },
    close: function () {
      this.isActive = false;
    }
  }
};
</script>

<style lang="less">
.drop-down {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  color: @white;
  font-weight: 500;
  font-size: 14px;
  cursor: default;
  line-height: 18px;
  &--active {
    & .drop-down__list {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }
  &__wrapper {
    position: relative;
    display: flex;
    align-items: baseline;
    cursor: pointer;
  }
  &__caption {
    padding: 5px 0;
    white-space: nowrap;
  }
  &__selected {
    flex-shrink: 0;
    padding: 5px 0 5px 10px;
    display: flex;
    align-items: center;
    color: @gold;
    white-space: nowrap;
  }
  &__arrow {
    flex-shrink: 0;
    margin-left: 10px;
  }
  &__list {
    box-sizing: border-box;
    position: absolute;
    top: 100%;
    left: -10px;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    border: 1px solid @white_10;
    background-color: @bg;
    box-shadow: 0px 16px 22px rgba(44, 50, 58, 0.28);
    transition: all linear 0.1s;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    cursor: default;
    z-index: 30;
  }
  &__item {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border: none;
    background: none;
    transition: color 0.2s;
    cursor: pointer;
    white-space: nowrap;
    &:hover,
    &:active {
      color: @gold;
    }
  }
  &__units {
    margin-left: 2px;
  }
  &__sorting-arrows {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    &--desc {
      .drop-down__sorting-arrow--desc {
        color: @gold;
      }
    }
    &--asc {
      .drop-down__sorting-arrow--asc {
        color: @gold;
      }
    }
  }
  &__sorting-arrow {
    flex-shrink: 0;
    color: @white_60;
    &--asc {
      margin: 0 0 4px;
      transform: rotate(180deg);
    }
  }
}
</style>
