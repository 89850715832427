<template>
  <div class="request" :class="{ 'request--active': requestActive }">
    <div class="request__wrapper">
      <button type="button" class="request__close-btn r-btn" @click="close()">
        <svg-icon width="16" height="16" name="x" class="btn-icon" />
      </button>

      <div class="request__semicircles"></div>

      <div class="request__big-word request__big-word--request-flat big-word">
        request
      </div>

      <div class="request__container">
        <div class="request__item-info">
          <h4 class="request__title">
            <span class="request__title-word">
              {{ $t("request.title[0]") }}
            </span>
            <span class="request__title-word">
              {{ $t("request.title[1]") }}
            </span>
          </h4>

          <p class="request__text">
            {{ $t("request.text") }}
          </p>
        </div>

        <FormInstance as="" v-slot="{ validate, values, setFieldValue }">
          <form
            ref="form"
            novalidate="novalidate"
            class="request__form"
            @submit.prevent="onSubmit(validate, values, setFieldValue)"
          >
            <div class="request__fields">
              <FormInputText
                class="request__field request__field--w50"
                :element="name"
                :validationCount="validationCount"
              />

              <FormInputText
                class="request__field request__field--w50"
                :element="phone"
                :validationCount="validationCount"
              />

              <FormInputEmail
                class="request__field"
                :element="email"
                :validationCount="validationCount"
              />
            </div>

            <div class="request__field request__field--mb20 field">
              <p class="field__checks-caption">
                {{ $t("request.checks_caption") }}
              </p>

              <div class="field__checks">
                <FormCheck class="field__check" :element="parking" />

                <FormCheck class="field__check" :element="storeroom" />
              </div>
            </div>

            <button type="submit" class="request__submit-btn btn">
              {{ $t("request.submit") }}
            </button>

            <p class="request__agreement">
              {{ $t("agreement[0]") }}
              <br />
              <a
                href="/privacy"
                target="_blank"
                class="request__agreement-link"
              >
                {{ $t("agreement[1]") }}
              </a>
              {{ $t("agreement[2]") }}
            </p>
          </form>
        </FormInstance>
      </div>
    </div>
  </div>
</template>

<script>
import FormInputText from "@/components/form/FormInputText";
import FormInputEmail from "@/components/form/FormInputEmail";
import FormCheck from "@/components/form/FormCheck";

export default {
  components: {
    FormInputText,
    FormInputEmail,
    FormCheck
  },
  props: ["flat_id"],
  data() {
    return {
      validationCount: 0,
      isRequest: false
    };
  },
  computed: {
    requestActive() {
      return this.$store.state.requestActive;
    },
    api() {
      return this.$store.state.api;
    },
    name() {
      return {
        name: "name",
        label: this.$t("fields.name.label"),
        placeholder: this.$t("fields.name.ph"),
        rules: {
          required: true
        }
      };
    },
    phone() {
      return {
        name: "phone",
        label: this.$t("fields.phone.label"),
        placeholder: this.$t("fields.phone.ph"),
        mask: "+### (##) ##-##-##",
        rules: {
          required: true
        }
      };
    },
    email() {
      return {
        name: "email",
        label: this.$t("fields.email.label"),
        placeholder: this.$t("fields.email.ph"),
        rules: {
          required: true
        }
      };
    },
    parking() {
      return {
        name: "parking",
        label: this.$t("fields.parking")
      };
    },
    storeroom() {
      return {
        name: "storeroom",
        label: this.$t("fields.storeroom")
      };
    }
  },
  methods: {
    close() {
      this.$store.state.requestActive = false;
    },
    onSubmit(validate, values, setFieldValue) {
      validate().then((result) => {
        this.validationCount += 1;
        if (result.valid) {
          this.onValidate(values, setFieldValue);
        }
      });
    },
    onValidate(values) {
      if (this.isRequest) return;

      this.isRequest = true;

      this.$axios({
        method: "post",
        url: this.api + "/callback",
        data: Object.assign(
          {
            flat_id: this.flat_id
          },
          values
        ),
        timeout: 90000
      })
        .then((response) => {
          this.isRequest = false;
          if (response && response.status == 200) {
            this.$toast.success(this.$t("requestMsg"));
            this.$store.state.requestActive = false;
          } else {
            this.$toast.error(this.$t("error"));
          }
        })
        .catch((error) => {
          this.isRequest = false;
          this.$toast.error(this.$t("error"));
          if (error && error.response) console.log(error.response);
        });
    }
  },
  watch: {
    requestActive: {
      handler: function (val) {
        if (!val && this.$root.$refs.scroll) this.$root.$refs.scroll.focus();
      },
      immediate: false
    }
  }
};
</script>
