export const clearFilters = {
  methods: {
    clearFilters() {
      this.$store.state.filter = {};
      this.$store.state.current_page = 1;
      this.$store.state.per_page = 10;
      this.$store.state.sort = ["floor", "asc"];
      this.$store.state.activeRow = 0;
      this.$store.state.floor = 0;
      this.$store.state.floorFlat = 0;
    }
  }
};
