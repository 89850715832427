<template>
  <div v-if="show" class="filter" :class="{ 'filter--active': active }">
    <div class="filter__wrapper">
      <header class="filter__header">
        <div class="filter__header-inner">
          <h4 class="filter__title">{{ $t("pages.flats.filter") }}</h4>

          <button
            type="button"
            class="filter__close-btn r-btn"
            @click="close()"
          >
            <svg-icon width="16" height="16" name="x" class="btn-icon" />
          </button>
        </div>
      </header>

      <div class="filter__main">
        <div class="filter__fields">
          <div v-if="hasFloor" class="filter__element field">
            <div class="field__label field__label--filter">
              {{ $t("params.floor") }}
            </div>
            <div class="field__range">
              <vue-slider
                v-model="floor"
                :dotSize="21"
                :height="1"
                :contained="true"
                :min="minFloor"
                :max="maxFloor"
                :data-min="floor[0]"
                :data-max="floor[1]"
                :interval="1"
                tooltip="none"
              ></vue-slider>
            </div>
          </div>

          <div v-if="hasArea" class="filter__element field">
            <div class="field__label field__label--filter">
              {{ $t("params.area") }}
            </div>
            <div class="field__range">
              <vue-slider
                v-model="area"
                :dotSize="21"
                :height="1"
                :contained="true"
                :min="minArea"
                :max="maxArea"
                :data-min="area[0]"
                :data-max="area[1]"
                :interval="1"
                tooltip="none"
              ></vue-slider>
            </div>
          </div>

          <div v-if="hasPrice" class="filter__element field">
            <div class="field__label field__label--filter">
              {{ $t("params.price2") }}
            </div>
            <div class="field__range">
              <vue-slider
                v-model="price"
                :dotSize="21"
                :height="1"
                :contained="true"
                :min="minPrice"
                :max="maxPrice"
                :data-min="price[0]"
                :data-max="price[1]"
                :interval="1"
                tooltip="none"
              ></vue-slider>
            </div>
          </div>

          <div
            v-if="hasRooms"
            class="filter__element filter__element--mb40 field"
          >
            <div class="field__label field__label--filter">
              {{ $t("params.rooms") }}
            </div>
            <div class="field__r-checks">
              <label
                v-for="(qty, qtyIndex) in availableRooms"
                :key="qtyIndex"
                class="field__r-check r-check"
              >
                <input
                  type="checkbox"
                  v-model="rooms"
                  :value="qty"
                  class="r-check__input"
                />

                <span class="r-btn r-btn--gold r-btn--txt r-check__r-btn">{{
                  qty
                }}</span>
              </label>
            </div>
          </div>

          <div class="filter__element filter__element--mb40 field">
            <div class="field__label field__label--filter">
              {{ $t("params.finish") }}
            </div>
            <div class="field__checks">
              <div class="field__check check">
                <label class="check__label">
                  <input
                    type="checkbox"
                    class="check__input"
                    v-model="finish"
                    value="base"
                  />
                  <span class="check__mark"></span>
                  <span class="check__caption">{{ $t("finish.base") }}</span>
                </label>
              </div>

              <div class="field__check check">
                <label class="check__label">
                  <input
                    type="checkbox"
                    class="check__input"
                    v-model="finish"
                    value="white"
                  />
                  <span class="check__mark"></span>
                  <span class="check__caption">{{ $t("finish.white") }}</span>
                </label>
              </div>

              <div class="field__check check">
                <label class="check__label">
                  <input
                    type="checkbox"
                    class="check__input"
                    v-model="finish"
                    value="full"
                  />
                  <span class="check__mark"></span>
                  <span class="check__caption">{{ $t("finish.full") }}</span>
                </label>
              </div>
            </div>
          </div>

          <div class="filter__element filter__element--mb40 field">
            <div class="field__label field__label--filter">
              {{ $t("params.type") }}
            </div>
            <div class="field__checks">
              <div class="field__check check">
                <label class="check__label">
                  <input
                    type="radio"
                    class="check__input"
                    v-model="floor_1"
                    :value="0"
                  />
                  <span class="check__mark check__mark--radio"></span>
                  <span class="check__caption">{{ $t("all") }}</span>
                </label>
              </div>

              <div class="field__check check">
                <label class="check__label">
                  <input
                    type="radio"
                    class="check__input"
                    v-model="floor_1"
                    :value="1"
                  />
                  <span class="check__mark check__mark--radio"></span>
                  <span class="check__caption">{{ $t("duplex") }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="filter__actions">
          <button
            v-if="showReset"
            type="button"
            class="filter__reset"
            :data-name="$t('reset')"
            @click="reset()"
          ></button>
          <button type="button" class="btn filter__btn" @click="apply()">
            {{ $t("apply") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FlatsFilter",
  data() {
    return {
      floor: ["", ""],
      area: ["", ""],
      price: ["", ""],
      rooms: [],
      finish: [],
      floor_1: 0,
      show: false
    };
  },
  computed: {
    active() {
      return this.$store.state.filterActive;
    },
    list() {
      return this.$store.getters.fullList;
    },
    availableRooms() {
      if (!this.list) return null;
      var seen = {};
      this.list.filter((item) => {
        return seen[item.rooms] ? false : (seen[item.rooms] = item.rooms);
      });
      return Object.values(seen);
    },
    hasRooms() {
      return this.availableRooms.length;
    },
    availableFloors() {
      return this.$store.getters.floors
        ? Object.keys(this.$store.getters.floors)
        : null;
    },
    minFloor() {
      return this.availableFloors ? Math.min(...this.availableFloors) : null;
    },
    maxFloor() {
      return this.availableFloors ? Math.max(...this.availableFloors) : null;
    },
    hasFloor() {
      return this.minFloor && this.maxFloor && this.minFloor != this.maxFloor;
    },
    availableAreas() {
      return this.list
        ? this.list.map((item) => {
            return item.area;
          })
        : null;
    },
    minArea() {
      return this.availableAreas
        ? Math.floor(Math.min(...this.availableAreas))
        : null;
    },
    maxArea() {
      return this.availableAreas
        ? Math.ceil(Math.max(...this.availableAreas))
        : null;
    },
    hasArea() {
      return this.minArea && this.maxArea && this.minArea != this.maxArea;
    },
    availablePrices() {
      return this.list
        ? this.list.map((item) => {
            return item.price;
          })
        : null;
    },
    minPrice() {
      return this.availablePrices
        ? Math.floor(Math.min(...this.availablePrices) / 1000)
        : null;
    },
    maxPrice() {
      return this.availablePrices
        ? Math.ceil(Math.max(...this.availablePrices) / 1000)
        : null;
    },
    hasPrice() {
      return this.minPrice && this.maxPrice && this.minPrice != this.maxPrice;
    },
    values() {
      const values = {};
      const floor = {
        min:
          this.floor[0] && this.floor[0] != this.minFloor
            ? this.floor[0]
            : null,
        max:
          this.floor[1] && this.floor[1] != this.maxFloor ? this.floor[1] : null
      };
      if (floor.min || floor.max) values.floor = floor;

      const area = {
        min: this.area[0] && this.area[0] != this.minArea ? this.area[0] : null,
        max: this.area[1] && this.area[1] != this.maxArea ? this.area[1] : null
      };
      if (area.min || area.max) values.area = area;

      const price = {
        min:
          this.price[0] && this.price[0] != this.minPrice
            ? this.price[0] * 1000
            : null,
        max:
          this.price[1] && this.price[1] != this.maxPrice
            ? this.price[1] * 1000
            : null
      };
      if (price.min || price.max) values.price = price;
      if (this.rooms.length) values.rooms = this.rooms;
      if (this.finish.length) values.finish = this.finish;
      if (this.floor_1) values.floor_1 = this.floor_1;

      return values;
    },
    showReset() {
      return Object.keys(this.values).length;
    }
  },
  methods: {
    close() {
      this.$store.state.filterActive = false;
    },
    apply() {
      this.$store.state.filter = JSON.parse(JSON.stringify(this.values));
      this.$store.state.current_page = 1;
      this.close();
    },
    reset() {
      this.floor = [this.minFloor, this.maxFloor];
      this.area = [this.minArea, this.maxArea];
      this.price = [this.minPrice, this.maxPrice];
      this.rooms = [];
      this.finish = [];
      this.floor_1 = 0;
      this.apply();
    }
  },
  created() {
    const filters = this.$store.state.filter;
    const floor = filters.floor || {};
    const area = filters.area || {};
    const price = filters.price || {};
    this.floor = [floor.min || this.minFloor, floor.max || this.maxFloor];
    this.area = [area.min || this.minArea, area.max || this.maxArea];
    this.price = [price.min || this.minPrice, price.max || this.maxPrice];
    this.rooms = filters.rooms || [];
    this.finish = filters.finish || [];
    this.floor_1 = filters.floor_1 || 0;

    this.show = true;
  },
  watch: {
    active: {
      handler: function (val) {
        if (!val && this.$root.$refs.scroll) this.$root.$refs.scroll.focus();
      },
      immediate: false
    }
  }
};
</script>

<style lang="less">
.filter {
  .custom-scroll-light;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: lighten(@bg, 2%);
  transform: translate3d(0, -100%, 0);
  transition: transform 0.4s;
  z-index: 1300;
  @media @bw1170 {
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
  }
  &--active {
    transform: translate3d(0, 0, 0);
  }

  &__wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    padding: 50px 0;
    @media @bw1170 {
      padding: 0;
    }
  }

  &__header {
    .container-filter;
    flex-shrink: 0;
    margin-bottom: 50px;
    @media @bw1170 {
      margin-bottom: 0;
      padding-top: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid @white_10;
    }
    @media @bw400 {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  &__header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 35px;
    border-bottom: 1px solid @white_10;
    @media @bw1170 {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
  &__main {
    .container-filter;
    display: flex;
    flex-direction: column;
    @media @bw1170 {
      .custom-scroll-light;
      height: 50px;
      flex-grow: 1;
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  &__title {
    margin: 0;
    color: @white;
    font-family: @font2;
    font-weight: 700;
    font-size: 72px;
    line-height: 0.92;
    text-transform: uppercase;
    letter-spacing: -0.02em;
    @media @bw1510 {
      font-size: 52px;
    }
    @media @bw1340 {
      font-size: 44px;
    }
    @media @bw400 {
      font-size: 36px;
    }
    @media @w1510bh750 {
      font-size: 52px;
    }
  }
  &__fields {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 0 -25px;
    @media @bw768 {
      margin: 0 -20px;
    }
  }
  &__element {
    width: 380px;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 60px;
    @media @bw1510 {
      margin-bottom: 40px;
    }
    @media @bw768 {
      margin-left: 20px;
      margin-right: 20px;
    }
    &--mb40 {
      margin-bottom: 40px;
      @media @bw1510 {
        margin-bottom: 20px;
      }
    }
  }
  &__actions {
    display: flex;
    align-items: center;
    @media @bw1510 {
      margin-top: 20px;
    }
    @media @bw1170 {
      flex-direction: column;
    }
  }
  &__btn {
    margin: 0 auto;
    max-width: 370px;
  }
  &__reset {
    display: flex;
    width: 0;
    padding: 0;
    border: none;
    background: none;
    font-size: 0;
    @media @bw1170 {
      order: 1;
      width: auto;
      margin-top: 40px;
    }
    &::after {
      content: attr(data-name);
      display: block;
      color: @red;
      font-family: @font2;
      font-weight: 700;
      font-size: 18px;
      line-height: 1;
      white-space: nowrap;
    }
  }
}
</style>
