<template>
  <div v-if="list" class="list" :class="{ 'list--tile': isTile }">
    <FlatsFilter />

    <div class="list__header">
      <h2 class="list__title list__title--table">
        {{ $t("pages.flats.sub_title[0]") }}
      </h2>

      <h2 class="list__title list__title--tile">
        {{ $t("pages.flats.sub_title[1]") }}
      </h2>

      <div class="list__btns">
        <button
          type="button"
          class="toggle-btn toggle-btn--2 list__status-btn"
          :class="{
            'toggle-btn--on': showRentList
          }"
          @click="toggleStatus()"
        >
          <span class="toggle"></span>
          <div class="toggle-btn__words">
            <span class="toggle-btn__word">
              {{ $t("status.free") }}
            </span>
            <span class="toggle-btn__word toggle-btn__word--2">
              {{ $t("status.rent") }}
            </span>
          </div>
        </button>

        <button
          type="button"
          class="
            toggle-btn toggle-btn--2 toggle-btn--disabled
            list__avilable-btn
          "
          :class="{
            'toggle-btn--on': available,
            'toggle-btn--active': !showRentList
          }"
          @click="toggleAvailable()"
        >
          <span class="toggle"></span>
          <div class="toggle-btn__words">
            <span class="toggle-btn__word">
              {{ $t("all") }}
            </span>
            <span class="toggle-btn__word toggle-btn__word--2">
              {{ $t("avilable") }}
            </span>
          </div>
        </button>

        <button
          type="button"
          class="list__grid-btn r-btn"
          :class="{ 'r-btn--active': !isTile }"
          @click="togleIsTile()"
        >
          <svg-icon
            width="20"
            height="14"
            name="layout_list"
            class="btn-icon"
          />
        </button>

        <button
          type="button"
          class="list__grid-btn r-btn"
          :class="{ 'r-btn--active': isTile }"
          @click="togleIsTile()"
        >
          <svg-icon width="16" height="16" name="tile" class="btn-icon" />
        </button>
      </div>
    </div>

    <div
      v-if="pageList.length"
      class="table list__table"
      :class="{
        'table--tile': isTile
      }"
      v-resize="getCellWidth"
    >
      <div class="table__wrapper">
        <div class="table__drop-downs">
          <DropDown
            class="table__sorting"
            :selected="sort[0]"
            :options="parameters"
            :callback="setSort"
            :locale="'table_sorting'"
            :caption="$t('sorting')"
            :sort="sort"
          />

          <DropDown
            class="table__per-page"
            :selected="per_page"
            :options="[10, 20, 30, 50]"
            :callback="setPerPage"
            :caption="$t('per_page')"
            :units="$t('pc')"
          />
        </div>

        <div v-if="!isTile" class="table__header">
          <button
            v-for="(tbtn, tbtnIndex) in parameters"
            :key="tbtnIndex"
            type="button"
            class="table__header-btn"
            :class="{
              'table__header-btn--desc': sort[0] == tbtn && sort[1] === 'desc',
              'table__header-btn--asc': sort[0] == tbtn && sort[1] === 'asc'
            }"
            :style="'width:' + thWidth[tbtnIndex]"
            @click="setSort(tbtn)"
          >
            {{ $t("params." + tbtn) }}

            <span class="table__header-btn-arrows">
              <svg-icon
                width="7"
                height="4"
                name="arrow"
                class="table__header-btn-arrow table__header-btn-arrow--asc"
              />

              <svg-icon
                width="7"
                height="4"
                name="arrow"
                class="table__header-btn-arrow table__header-btn-arrow--desc"
              />
            </span>
          </button>
        </div>

        <div class="table__scroll-wrapper">
          <div ref="tableScroll" class="table__scroll">
            <table ref="tableTable" class="list__table table__table">
              <tbody class="table__tbody">
                <tr
                  v-for="(flat, flatIndex) in pageList"
                  :key="flat.id"
                  ref="tableTr"
                  class="table__tr"
                  :class="{
                    'table__tr--active': activeRow === flatIndex,
                    'table__tr--disabled':
                      flat.status !== 'free' && flat.status !== 'rent'
                  }"
                  @mouseenter="$store.state.activeRow = flatIndex"
                >
                  <td
                    class="table__td table__td--floor"
                    :data-attr="$t('params.floor')"
                  >
                    <div class="table__cell">
                      <span v-if="!flat.layout_1" class="table__td-floor">
                        {{ flat.floor }}
                      </span>

                      <span v-if="flat.layout_1" class="table__td-floors">
                        {{ flat.floor + "/" + (+flat.floor + 1) }}
                      </span>

                      <button
                        v-if="flat.layout_1"
                        type="button"
                        :title="$t('duplex')"
                        class="toggle toggle--gold table__td-floors-btn"
                        :class="{ 'toggle--on': layouts[flat.id] }"
                        @click="
                          layouts[flat.id] = layouts[flat.id] ? false : true
                        "
                      >
                        <span
                          class="toggle__caption"
                          :data-off="flat.floor"
                          :data-on="+flat.floor + 1"
                        ></span>
                      </button>
                    </div>
                  </td>

                  <td
                    class="table__td table__td--num"
                    :data-attr="$t('params.id')"
                  >
                    <div class="table__cell">
                      {{ flat.id }}
                    </div>
                  </td>

                  <td
                    class="table__td table__td--rooms"
                    :data-attr="$t('params.rooms')"
                  >
                    <div class="table__cell">
                      {{ flat.rooms }}
                    </div>
                  </td>

                  <td class="table__td" :data-attr="$t('params.area')">
                    <div class="table__cell">
                      {{ flat.area }}
                    </div>
                  </td>

                  <td
                    class="table__td table__td--price"
                    :data-attr="$t('params.price')"
                    :class="{
                      'table__td--w100': flat.price_on_request
                    }"
                  >
                    <button
                      v-if="flat.price_on_request"
                      type="button"
                      class="table__cell-btn"
                      :class="{
                        'table__cell-btn--disabled': flat.status === 'sold'
                      }"
                      @click="openRequestFlat(flat.id)"
                    >
                      {{ $t("requestPrice") }}
                    </button>
                    <div v-else class="table__cell">
                      {{ $global.numberSpaces(flat.price) || "-" }}
                    </div>
                  </td>

                  <td
                    class="table__td table__td--finish"
                    :data-attr="$t('params.finish')"
                  >
                    <div class="table__cell">
                      {{ $t("finish." + flat.finish) }}
                    </div>
                  </td>

                  <td
                    class="table__td table__td--status"
                    :data-attr="$t('params.status_caption')"
                  >
                    <div class="table__cell">
                      {{ $t("status." + flat.status) }}
                    </div>
                  </td>

                  <td class="table__td table__td--link">
                    <div class="table__cell">
                      <localized-link
                        v-if="flat.status !== 'sold'"
                        :to="'/flats/flat/' + flat.id"
                        class="table__td-link"
                      >
                        <svg-icon
                          width="14"
                          height="10"
                          name="arrow_right"
                          class="table__td-svg"
                        />
                        <span class="table__td-link-tile"></span>
                      </localized-link>
                    </div>

                    <div v-if="flat.layout_1" class="table__td-layouts">
                      <button
                        type="button"
                        class="toggle toggle--gold table__td-layouts-btn"
                        :class="{ 'toggle--on': layouts[flat.id] }"
                        @click="
                          layouts[flat.id] = layouts[flat.id] ? false : true
                        "
                      >
                        <span
                          class="toggle__caption"
                          data-off="1"
                          data-on="2"
                        ></span>
                      </button>

                      <span class="table__td-layouts-caption">
                        {{ $t("duplex") }}
                      </span>
                    </div>

                    <div class="table__td-images">
                      <img
                        class="table__td-img"
                        :src="layouts[flat.id] ? flat.layout_1 : flat.layout"
                        alt="layout"
                        @load="loaded += 1"
                      />

                      <img
                        class="table__td-img table__td-img--furniture"
                        :src="
                          layouts[flat.id] ? flat.furniture_1 : flat.furniture
                        "
                        alt="furniture"
                      />

                      <div
                        v-if="flat.status !== 'free'"
                        :data-status="$t(flat.status)"
                        class="table__td-img-status"
                      ></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <paginator
              v-if="list.length"
              :total="last_page"
              :current="current_page"
              :callback="setPage"
              class="table__paginator"
              :class="{ 'paginator--compact': !isTile }"
            />

            <div v-if="list.length" class="table__footnote">
              <sup class="table__footnote-sup">*</sup>
              <localized-link to="/documentation" class="table__note-link">
                {{ $t("price_note") }}
              </localized-link>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="activeRow >= 0 && pageList[activeRow]"
        class="table__images"
        :class="{
          'table__images--disabled': pageList[activeRow].status === 'sold'
        }"
      >
        <img
          :src="
            layouts[pageList[activeRow].id]
              ? pageList[activeRow].layout_1
              : pageList[activeRow].layout
          "
          alt="layout"
          class="table__image"
        />
        <div
          v-if="pageList[activeRow].status !== 'free'"
          class="table__images-status"
          :data-status="$t(pageList[activeRow].status)"
        ></div>
      </div>
    </div>

    <p class="list__empty" v-if="!pageList.length">{{ $t("not_found") }}</p>
  </div>
</template>

<script>
import FlatsFilter from "@/components/FlatsFilter";
import DropDown from "@/components/DropDown";
import Paginator from "@/components/Paginator";

export default {
  name: "FlatsList",
  components: {
    FlatsFilter,
    DropDown,
    Paginator
  },
  emits: ["loaded", "openRequest"],
  data() {
    return {
      parameters: [
        "floor",
        "id",
        "rooms",
        "area",
        "price",
        "finish",
        "status_caption"
      ],
      layouts: {},
      thWidth: [],
      loaded: 0
    };
  },
  computed: {
    available() {
      return this.$store.state.available;
    },
    showRentList() {
      return this.$store.state.showRentList;
    },
    isTile() {
      return this.$store.state.isTile;
    },
    locale() {
      return this.$i18n.locale;
    },
    current_page() {
      return this.$store.state.current_page;
    },
    per_page() {
      return this.$store.state.per_page;
    },
    sort() {
      return this.$store.state.sort;
    },
    filter() {
      return this.$store.state.filter;
    },
    list() {
      // const defaultList = this.available
      //   ? this.$store.getters.list
      //   : this.$store.getters.fullList;

      const defaultList = this.showRentList
        ? this.$store.getters.listRent
        : this.available
        ? this.$store.getters.list
        : this.$store.getters.fullList;

      if (!defaultList) return null;

      const filtered = defaultList.filter((flat) => {
        const fKeys = Object.keys(this.filter);
        const notInList = fKeys.some((key) => {
          if (Array.isArray(this.filter[key])) {
            return !this.filter[key].includes(flat[key]);
          } else if (typeof this.filter[key] === "object") {
            return (
              (this.filter[key].min &&
                flat[key] &&
                flat[key] <
                  (key === "floor" && flat.floor_1
                    ? this.filter[key].min - 1
                    : this.filter[key].min)) ||
              (this.filter[key].max &&
                flat[key] &&
                flat[key] > this.filter[key].max)
            );
          } else if (this.filter[key]) {
            return key in flat ? false : true;
          } else {
            return false;
          }
        });
        return !notInList;
      });

      const field = this.sort[0];
      const direction = this.sort[1] === "asc" ? 1 : -1;

      const list = filtered.sort((a, b) => {
        let a_value = a[field];
        let b_value = b[field];

        if (field === "finish") {
          a_value = this.$t("finish." + a_value);
          b_value = this.$t("finish." + b_value);
        } else {
          a_value = +a_value;
          b_value = +b_value;
        }

        return a_value > b_value
          ? direction
          : b_value > a_value
          ? -direction
          : 0;
      });
      return list;
    },
    pageList() {
      return this.list
        ? this.list.slice(
            (this.current_page - 1) * this.per_page,
            this.current_page * this.per_page
          )
        : [];
    },
    total() {
      return this.list ? this.list.length : 0;
    },
    last_page() {
      return Math.ceil(this.total / this.per_page);
    },
    activeRow() {
      return this.$store.state.activeRow;
    }
  },
  methods: {
    openRequestFlat(id) {
      if (!id) return;
      this.$emit("openRequest", id);
    },
    togleIsTile() {
      this.$store.state.isTile = !this.isTile;
    },
    toggleStatus() {
      this.$store.state.showRentList = !this.$store.state.showRentList;
    },
    toggleAvailable() {
      this.$store.state.available = !this.$store.state.available;
    },
    getCellWidth() {
      if (this.isTile) return;
      this.$nextTick(() => {
        this.thWidth = [];
        if (!this.$refs.tableTr) return;
        const cells = this.$refs.tableTr.querySelectorAll(".table__td");
        for (let i = 0; i < cells.length; ++i) {
          this.thWidth.push(cells[i].offsetWidth + "px");
        }
      });
    },
    setPage(page) {
      this.$store.state.current_page = page;
      if (!this.pageList[this.activeRow] && this.pageList[0])
        this.$store.state.activeRow = 0;
      this.$nextTick(() => {
        if (this.isTile || this.$matchMedia.bw1170) {
          this.$scrollTo("#appScroll", 0, {
            container: "#appScroll"
          });
        } else if (this.$refs.tableScroll) {
          this.$scrollTo(this.$refs.tableScroll, 0, {
            container: this.$refs.tableScroll
          });
        }
      });
    },
    setPerPage(per_page) {
      this.$store.state.per_page = per_page;
      this.setPage(1);
    },
    setSort(param) {
      if (this.sort[0] === param) {
        this.$store.state.sort[1] = this.sort[1] === "asc" ? "desc" : "asc";
      } else {
        this.$store.state.sort[0] = param;
        this.$store.state.sort[1] = "desc";
      }
      this.setPage(1);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getCellWidth();

      if (this.$store.state.activeRow) {
        if (this.isTile || this.$matchMedia.bw1170) {
          this.$scrollTo(".table__tr--active", 0, {
            container: "#appScroll",
            offset: -50
          });
        } else if (this.$refs.tableScroll) {
          this.$scrollTo(".table__tr--active", 0, {
            container: this.$refs.tableScroll,
            offset: -22
          });
        }
      }

      if (this.list && this.loaded >= this.pageList.length) {
        this.$emit("loaded");
      } else {
        const readyLoop = setInterval(() => {
          if (this.list && this.loaded >= this.pageList.length) {
            clearInterval(readyLoop);
            this.$emit("loaded");
          }
        }, 200);
      }
    });
  },
  watch: {
    pageList() {
      this.getCellWidth();
    },
    isTile() {
      this.getCellWidth();
    },
    locale() {
      this.getCellWidth();
    },
    available() {
      this.setPage(1);
    },
    showRentList() {
      this.setPage(1);
    }
  }
};
</script>

<style lang="less">
.list {
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 200px 50px 0 340px;
  overflow: hidden;
  @media @bw1680 {
    padding-left: 280px;
  }
  @media @bw1510 {
    padding: 135px 30px 0 130px;
  }
  @media @bw1170 {
    padding: 0 30px 40px;
  }
  @media @bw768 {
    padding: 0 20px 40px;
  }
  @media @w1510bh750 {
    padding: 135px 30px 0 130px;
  }
  &--tile {
    .list__title {
      &--table {
        display: none;
      }
      &--tile {
        display: block;
        @media @bw1170 {
          display: none;
        }
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    margin: 0 0 35px;
    @media @bw1510 {
      margin: 0 0 25px;
    }
    @media @bw1170 {
      //display: none;
    }

    @media @w1510bh750 {
      margin: 0 0 25px;
    }
  }
  &__title {
    box-sizing: border-box;
    width: 46%;
    min-width: 470px;
    margin: 0;
    padding-right: 20px;
    font-family: @font2;
    font-weight: 700;
    font-size: 72px;
    line-height: 0.92;
    color: @white;
    @media @bw1860 {
      font-size: 68px;
    }
    @media @bw1510 {
      font-size: 52px;
    }
    @media @bw1340 {
      font-size: 44px;
    }
    @media @bw1170 {
      display: none;
    }
    @media @w1510bh750 {
      font-size: 52px;
    }
    &--tile {
      display: none;
      min-width: 575px;
      @media @bw1340 {
        min-width: 520px;
      }
      @media @bw1170 {
        display: none;
      }
    }
  }
  &__btns {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 20px;
    @media @bw1340 {
      margin-left: 0px;
    }
  }

  // &__btn-wrap {
  //   min-width: 90px;
  //   margin-right: 45px;
  //   @media @bw1340 {
  //     margin-right: 20px;
  //   }
  // }

  &__status-btn {
    margin-right: 50px;
    @media @bw768 {
      margin-right: 40px;
    }
  }

  &__avilable-btn {
    margin-right: auto;
  }

  &__grid-btn {
    margin-left: 20px;
    @media @bw1170 {
      display: none;
    }
  }
  &__empty {
    color: @white_60;
    font-size: 18px;
    line-height: 1.2;
  }
}
</style>
