<template>
  <main v-if="show" class="app__page flats">
    <link
      rel="prefetch"
      as="image"
      :href="require('@/assets/images/building.png')"
    />

    <header class="flats__header">
      <h1 class="flats__title">
        {{ $t("pages.flats.title") }}
      </h1>

      <button
        v-if="showList"
        type="button"
        class="flats__filter-btn r-btn"
        :class="{ 'r-btn--active-pointer': hasFilter }"
        @click="openFilter()"
      >
        <svg-icon width="20" height="15" name="filter" class="btn-icon" />

        <span class="r-btn__caption r-btn__caption--1510">
          {{ $t("pages.flats.filter") }}
        </span>
      </button>
    </header>

    <div v-if="!$matchMedia.bw1170" class="flats__btns">
      <button
        type="button"
        class="flats__btn flats__btn--floors r-btn"
        :class="{ 'r-btn--active': !layouts }"
        @click="toogle()"
      >
        <svg-icon width="12" height="24" name="floor" class="btn-icon" />

        <span class="r-btn__caption r-btn__caption--1510">
          {{ $t("pages.flats.floors") }}
        </span>
      </button>

      <button
        type="button"
        class="flats__btn flats__btn--layouts r-btn"
        :class="{ 'r-btn--active': layouts }"
        @click="toogle(true)"
      >
        <svg-icon width="20" height="20" name="layout" class="btn-icon" />

        <span class="r-btn__caption r-btn__caption--1510">
          {{ $t("pages.flats.layouts") }}
        </span>
      </button>
    </div>

    <keep-alive>
      <FlatsFloors
        v-if="floors && !showList"
        @loaded="loaded = loaded + 1"
        @openRequest="openRequestFlat"
        class="flats__floors"
      />
    </keep-alive>

    <FlatsList
      v-if="showList"
      @loaded="loaded = loaded + 1"
      @openRequest="openRequestFlat"
      class="flats__list"
    />

    <RequestFlat :flat_id="flat_id" />
  </main>
</template>

<script>
import { pageLoaded } from "@/mixins/pageLoaded.js";
import FlatsFloors from "@/components/FlatsFloors";
import FlatsList from "@/components/FlatsList";
import RequestFlat from "@/components/RequestFlat";
import { clearFilters } from "@/mixins/clearFilters.js";

export default {
  name: "flats",
  components: {
    FlatsFloors,
    FlatsList,
    RequestFlat
  },
  mixins: [pageLoaded, clearFilters],
  data() {
    return {
      flat_id: 0,
      layouts: false,
      show: false
    };
  },
  computed: {
    available() {
      return this.$store.state.available;
    },
    showList() {
      return this.layouts || this.$matchMedia.bw1170;
    },
    hasFilter() {
      return this.$store.getters.hasFilter;
    },
    floors() {
      return this.$store.getters.floors;
    }
  },
  methods: {
    openRequestFlat(id) {
      if (!id) return;
      this.flat_id = id;
      this.$store.state.requestActive = true;
    },
    toogle(on = false) {
      const query = on ? { layouts: 1 } : {};
      this.$router.replace({ query });
    },
    openFilter() {
      this.$store.state.filterActive = true;
    },
    toggleAvailable() {
      this.$store.state.available = !this.$store.state.available;
    }
  },
  created() {
    this.layouts = this.$route.query.layouts == 1 ? true : false;
    this.show = true;
  },
  mounted() {
    this.$nextTick(() => {
      this.loaded = this.loaded + 1;
    });
  },
  beforeRouteLeave(to, from, next) {
    this.$store.state.filterActive = false;
    if (to.name !== "flats-flat-id") {
      this.clearFilters();
    }
    next();
  },
  watch: {
    $route: {
      handler: function (val) {
        this.layouts = val.query.layouts == 1 ? true : false;
      },
      immediate: false
    }
  }
};
</script>

<style lang="less">
.flats {
  box-sizing: border-box;
  @media @bw1170 {
    padding: 150px 0 0;
  }
  @media @bw1020 {
    padding: 60px 0 0;
  }
  @media @bw768 {
    padding: 40px 0 0;
  }
  &__header {
    @media @bw1170 {
      display: flex;
      align-items: center;
      padding: 0 30px;
      margin-bottom: 25px;
    }
    @media @bw768 {
      flex-wrap: wrap;
      padding: 0 20px;
    }
  }
  &__title {
    display: none;
    @media @bw1170 {
      display: block;
      margin: 0;
      font-family: @font2;
      font-weight: 700;
      font-size: 44px;
      line-height: 0.82;
      color: @white;
    }
    @media @bw768 {
      margin-right: auto;
      font-size: 36px;
      line-height: 1.1;
      letter-spacing: -0.02em;
    }
    @media @bw370 {
      font-size: 28px;
    }
  }
  &__avilable-btn {
    display: none;
    margin-left: 40px;
    margin-right: auto;
    @media @bw1170 {
      display: flex;
    }
    @media @bw768 {
      order: 1;
      margin-left: 0;
    }
  }
  &__filter-btn {
    position: fixed;
    left: 50px;
    top: 200px;
    @media @bw1510 {
      top: 135px;
      left: 30px;
    }
    @media @bw1170 {
      margin-left: auto;
      position: static;
      left: auto;
      top: auto;
    }
    @media @w1510bh750 {
      left: 30px;
      top: 135px;
    }
  }
  &__btns {
    position: fixed;
    left: 50px;
    top: 260px;
    bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 50;
    @media @bw1510 {
      top: 195px;
      left: 30px;
    }
    @media @bw1170 {
      display: none;
    }
    @media @w1510bh750 {
      top: 195px;
      left: 30px;
    }
  }
  &__btn {
    margin: 10px 0;
  }
}
</style>
