<template>
  <div class="paginator" v-if="totalPages > 1">
    <button
      type="button"
      class="paginator__arrow paginator__arrow--prev"
      :class="{ 'paginator__arrow--disabled': currentPage === 1 }"
      @click="callback(currentPage - 1)"
    >
      {{ $t("paginator.back") }}
    </button>

    <div class="paginator__links">
      <button
        type="button"
        class="paginator__link"
        v-for="pageNumber in pagination"
        :key="pageNumber"
        :class="{
          'paginator__link--active': currentPage === pageNumber,
          'paginator__link--last':
            pageNumber == totalPages && Math.abs(pageNumber - currentPage) > 3,
          'paginator__link--first':
            pageNumber == 1 && Math.abs(pageNumber - currentPage) > 3
        }"
        @click="callback(pageNumber)"
      >
        {{ pageNumber }}
      </button>
    </div>

    <button
      type="button"
      class="paginator__arrow paginator__arrow--next"
      :class="{ 'paginator__arrow--disabled': currentPage === totalPages }"
      @click="callback(currentPage + 1)"
    >
      {{ $t("paginator.next") }}
    </button>
  </div>
</template>

<script>
export default {
  props: ["total", "current", "callback"],
  computed: {
    totalPages() {
      return this.total && this.total > 1 ? this.total : 1;
    },
    currentPage() {
      if (!this.current) return 1;

      return this.current < 1
        ? 1
        : this.current > this.total
        ? this.total
        : this.current;
    },
    pagination() {
      const pg = {
        1: 1
      };
      pg[this.totalPages] = this.totalPages;
      pg[this.currentPage] = this.currentPage;

      if (this.currentPage - 2 > 1)
        pg[this.currentPage - 2] = this.currentPage - 2;
      if (this.currentPage - 1 > 1)
        pg[this.currentPage - 1] = this.currentPage - 1;

      if (this.currentPage + 2 < this.totalPages)
        pg[this.currentPage + 2] = this.currentPage + 2;
      if (this.currentPage + 1 < this.totalPages)
        pg[this.currentPage + 1] = this.currentPage + 1;

      return pg;
    }
  }
};
</script>

<style lang="less">
.paginator {
  display: flex;
  //align-items: flex-start;
  align-items: center;
  justify-content: space-between;
  @media @bw1170 {
    flex-wrap: wrap;
    justify-content: center;
  }
  &--compact {
    flex-wrap: wrap;
    justify-content: center;
    .paginator__links {
      order: 1;
      width: 100%;
    }
    .paginator__link {
      margin: 20px 10px 0;
      @media @bw400 {
        margin: 20px 5px 0;
      }
      &--first {
        margin-right: 81px;
        @media @bw400 {
          margin-right: 63px;
        }
        &::after {
          left: 100%;
          margin-left: 20px;
          @media @bw400 {
            margin-left: 10px;
          }
        }
      }
      &--last {
        margin-left: 81px;
        @media @bw400 {
          margin-left: 63px;
        }
        &::after {
          right: 100%;
          margin-right: 20px;
          @media @bw400 {
            margin-right: 10px;
          }
        }
      }
    }
    .paginator__arrow {
      flex-grow: 1;
      margin: 0 10px 10px 0;
      min-width: 120px;
      width: calc(50% - 10px);
      max-width: calc(50% -10px);
      &--next {
        margin: 0 0 10px 10px;
      }
    }
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-shrink: 0;
    @media @bw1170 {
      order: 1;
      width: 100%;
    }
  }

  &__link {
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 51px;
    height: 51px;
    border-radius: 50%;
    margin: 0px 10px;
    padding: 0 10px;
    border: 1px solid @gold;
    background: transparent;
    color: @gold;
    font-family: @font2;
    font-weight: 700;
    font-size: 21px;
    line-height: 1;
    text-align: center;
    // transition: background-color 0.2s, color 0.2s;
    @media @bw1170 {
      margin: 20px 10px 0;
    }
    @media @bw400 {
      width: 48px;
      height: 48px;
    }
    @media @bw400 {
      margin: 20px 5px 0;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover,
    &:active,
    &--active {
      color: @dark2;
      background-color: @gold;
    }
    &--active {
      pointer-events: none;
    }
    &--first,
    &--last {
      &::after {
        box-sizing: border-box;
        content: "...";
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        width: 51px;
        height: 51px;
        border-radius: 50%;
        border: 1px solid @gold;
        color: @gold;
        pointer-events: none;
        @media @bw400 {
          width: 48px;
          height: 48px;
        }
      }
    }
    &--first {
      margin-right: 81px;
      @media @bw400 {
        margin-right: 53px;
      }
      &::after {
        left: 100%;
        margin-left: 20px;
        @media @bw400 {
          margin-left: 10px;
        }
      }
    }
    &--last {
      margin-left: 81px;
      @media @bw400 {
        margin-left: 63px;
      }
      &::after {
        right: 100%;
        margin-right: 20px;
        @media @bw400 {
          margin-right: 10px;
        }
      }
    }
  }

  &__arrow {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px 0 0;
    padding: 0;
    max-width: 326px;
    width: 100%;
    height: 51px;
    border-radius: 40px;
    background-color: transparent;
    border: 1px solid @gold;
    opacity: 1;
    // transition: background-color 0.2s, color 0.2s, border-color 0.2s;
    text-transform: uppercase;
    font-weight: 700;
    font-family: @font2;
    font-size: 18px;
    line-height: 1;
    color: @gold;
    @media @bw1170 {
      margin: 0 10px 20px 0;
      max-width: calc(50% -10px);
      width: calc(50% - 10px);
      min-width: 120px;
    }
    @media @bw400 {
      height: 48px;
    }
    &--next {
      margin: 0 0 0 20px;
      @media @bw1170 {
        margin: 0 0 20px 10px;
      }
    }
    &--disabled {
      color: @white_10;
      border-color: @white_10;
      pointer-events: none;
    }
    &:hover,
    &:active {
      background-color: @gold;
      color: @dark2;
    }
  }
}
</style>
