<template>
  <div class="check">
    <FormField
      v-if="element"
      :name="element.name"
      :label="'«' + element.label + '»'"
      :rules="element.rules"
      v-model="innerValue"
      v-slot="{ errorMessage }"
    >
      <label class="check__label">
        <input v-model="innerValue" type="checkbox" class="check__input" />

        <span class="check__mark"></span>
        <span
          v-if="element.label"
          class="check__caption"
          :class="{
            'check__caption--required': element.rules && element.rules.required
          }"
        >
          {{ element.label }}
        </span>
      </label>

      <span
        v-if="errorMessage && (isNaN(validationCount) || validationCount > 0)"
        class="check__error"
      >
        {{ errorMessage }}
      </span>
    </FormField>
  </div>
</template>

<script>
export default {
  name: "FormCheck",
  props: ["element", "initialValue", "validationCount"],
  data: () => ({
    innerValue: false
  }),
  computed: {
    rules() {
      return this.element.rules && this.element.rules.required
        ? { required: { allowFalse: false } }
        : null;
    }
  },
  created() {
    if (this.initialValue) this.innerValue = true;
  },
  methods: {}
};
</script>
